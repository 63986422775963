<template>
    <div @mousedown="showTag = false" class="container" id="relatory">
      <div class="header">
        <BaseHeader
          :title="`Relatório de tags ${management.title ? '- ' + management.title : ''}`"
          style="padding: 0 !important"
          :navigation="navigation"
        ></BaseHeader>
      </div>
  
      <div class="box mb-4">
          <div class="grid-filters">
            <div>
              <div class="label-header">
                <img src="@/assets/img/icons/formulario.svg" />
                <label for="Vendedor">Formulários</label>
              </div>
  
              <BaseSelect
                :selectModel="selectedForms"
                track-by="id"
                placeholder="Pesquise por um formulário"
                :array="forms"
                :multiple="true"
                :searchable="true"
                :taggable="true"
                class=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                @search="debounceForms($event)"
                @callback="getForms($event)"
                @change="selectedForms = $event, alertAlteration()"
                :loading="loadingForms"
                :disabled="generating"
                :watch="true"
                ><span slot="noResult">Nenhum formulário encontrado.</span>
              </BaseSelect>
            </div>
  
            <div>
              <div class="label-header">
                <img src="@/assets/img/icons/affiliate.svg" />
                <label for="Vendedor">Vendedores</label>
              </div>
  
              <BaseSelect
                id="vendedor"
                :selectModel="selectedSellers"
                track-by="id"
                :array="sellers"
                specificType="sellers"
                placeholder="Pesquise por vendedores"
                trackname="email"
                :multiple="true"
                :searchable="true"
                @search="debounceSeller($event)"
                @callback="getSellers($event)"
                @change="verifySellersSelected($event)"
                :disabled="generating"
                :watch="true"
              >
              </BaseSelect>
            </div>
  
            <div class="input-box">
              <div style="margin: 0 !important;" class="label-header">
                <img src="@/assets/img/icons/calendar.svg" alt="calendar">
                <label>Data</label>
              </div>
              <date-range-picker
                ref="picker"
                opens="left"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                v-model="dateRange"
                @update="alertAlteration()"
                :disabled="generating"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template v-else v-slot:input> Selecione as datas </template>
              </date-range-picker>
            </div>
          </div>
          <div class="tag-header mt-3">
            <div>
                <div ref="tags" style="gap: 5px; flex-wrap: wrap; max-width: 600px;" class="d-flex align-items-center" v-show="selectedTags.length">
                  <span
                    v-for="(tag, index) in selectedTags" :key="index"
                    class="tag-body"
                    :class="`${tag.color ? tag.color : 'default'}_color`"
                    @click="removeTag(index)"
                    v-b-tooltip.hover
                    :title="tag.name"
                  >
                    {{ tag.name }}
                  </span>
                </div>
      
                <div v-show="!selectedTags.length">
                  <p>Nenhuma tag selecionada</p>
                </div>
  
            </div>
            <div style="gap: 20px;" class="d-flex align-items-center">
                <div class="actions">
                  <img
                    class="icon" 
                    src="@/assets/editor/upload-cloud.svg" alt="save management"
                    v-b-tooltip.hover
                    title="Clique aqui para salvar o relatório"
                    @click="saveManagement()"
                  />

                  <img
                    v-if="typeGraph === 'horizontal-bar'"
                    @click="typeGraph = 'bar'" 
                    class="icon graph-icon" 
                    src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                    v-b-tooltip.hover
                    title="Clique aqui para apresentar as métricas em formato de barra (vertical)"
                  />


                  <img
                    v-if="typeGraph === 'pizza'"
                    @click="typeGraph = 'horizontal-bar'" 
                    class="icon graph-icon rotate" 
                    src="@/assets/img/icons/chart-bar.svg" alt="bar-graph"
                    v-b-tooltip.hover
                    title="Clique aqui para apresentar as métricas em formato de barra (horizontal)"
                  />
  
                  <img
                    v-if="typeGraph === 'bar'"
                    @click="typeGraph = 'pizza'" 
                    class="icon graph-icon" 
                    src="@/assets/img/icons/chart-pie-slice.svg" alt="pie-graph"
                    v-b-tooltip.hover
                    title="Clique aqui para apresentar as métricas em formato de pizza"
                  />
  
  
                  <img
                    ref="reload"
                    @click="generateGraph()" 
                    class="icon reload" 
                    src="@/assets/img/icons/sync.svg" alt="reload"
                    v-b-tooltip.hover
                    title="Clique aqui para atualizar seu relatório"
                  />
              </div>
              <div class="select-tag">
              <BaseButton ref="tagButton" v-if="selectedTags.length < 9" @click="showTag = !generating ? !showTag : false" tabindex="0" variant="primary" class="add-button">Adicionar tag</BaseButton>
    
              <BaseButton 
                ref="tagButton" 
                v-else 
                @click="showTag = !showTag" 
                variant="primary" 
                class="add-button"
                v-b-tooltip.hover
                title="É possível filtrar apenas por nove tags"
                @mousedown.stop
              >
                Adicionar tag
              </BaseButton>
    
              <section
                v-if="showTag && selectedTags.length < 9"
                class="lead-tags drop-bottom"
                id="tags-all"
                @mousedown.stop
              >
                <img
                  src="@/assets/img/icons/search.svg"
                  class="Pointer searchIcon"
                />
                <input type="text" v-model="tagSearch" />
                <div v-if="tags.length">
                  <span
                    v-for="(item, index) in filterSearchTags"
                    :key="index"
                    style="margin: 0; padding: 0"
                  >
                    <span
                      @click="addTag(item)"
                      class="container-tag"
                      :class="`${item.color ? item.color : 'default'}_color`"
                    >
                      <span class="lead-tag-name">{{ item.name }}</span>
                    </span>
                  </span>
                  <span
                    class="add-tag"
                    v-if="tags.length == selectedTags.length"
                    style="
                      background-color: transparent !important;
                      cursor: default !important;
                    "
                    >Todas as tags selecionadas</span
                  >
                </div>
                <span v-else>Nenhuma tag encontrada</span>
              </section>
            </div>
          </div>
        </div>
      </div>
  
      <div v-show="!graphs.length" class="box graphs-container mt-4">
        <div ref="generateGraph" @click="generateGraph()" class="add-graph">
          <img v-if="!generatedOnce" class="icon" src="@/assets/icons/add2.svg" alt="add-icon" />
          <p>{{ generateState }}</p>
        </div>
      </div>
      <div v-for="(graph, index) in graphs" :key="index" class="box graphs-container mt-4">
        <p class="seller-name">{{ graph.sellerName }}</p>
  
        <div class="grid-graph" v-if="graphs.length">
            <apexchart
              v-show="typeGraph === 'horizontal-bar'"
              type="bar"
              :options="returnHorizontalBarOptions(graph.graph)"
              :series="returnHorizontalBarsValues(graph.graph)"
              class="bar-chart mt-4"
              id="funnel-chart"
            />

            <apexchart
              v-show="typeGraph === 'bar'"
              type="bar"
              :options="returnBarOptions(graph.graph)"
              :series="returnBarValues(graph.graph)"
              class="bar-chart mt-4"
            />

            <apexchart
              v-show="typeGraph === 'pizza'"
              id="pizzaGraph"
              type="pie"
              class="mt-4"
              :options="returnPizzaOptions(graph.graph)"
              :series="returnPizzaValues(graph.graph)"
            />
        </div>
      </div>
      <ModalRelatorio
        :tags="selectedTags" 
        :forms="selectedForms"  
        :sellers="selectedSellers" 
        :management="management"
        :typeGraph="typeGraph"
        @updateManagement="management = $event"
      />
    </div>
  </template>
  
  <script>
  //Utils
  import moment from "moment-timezone";
  import _ from "lodash";
  
  //Components
  import BaseButton from "@/components/BaseButton";
  import ModalRelatorio from "./ModalRelatorio.vue";
  import DateRangePicker from "vue2-daterange-picker";
  import VueApexCharts from "vue-apexcharts";
  
  
  //Services
  import TagService from "@/services/resources/TagService";
  const serviceTag = TagService.build();
  
  import SellerListService from "@/services/resources/SellerListService";
  const serviceSeller = SellerListService.build();
  
  import FormService from "@/services/resources/FormService";
  const serviceForm = FormService.build();
  
  export default {
    components: {
      BaseButton,
      ModalRelatorio,
      DateRangePicker,
      apexchart: VueApexCharts
    },
    data() {
      return {
        navigation: [
          { link: "Estratégico", to: "/dynamicRoute/estrategico" },
          { link: "Relatório de tags", to: "/dynamicRoute/estrategico/relatorio-tag/lista" },
        ],
        tags: [],
        selectedTags: [],
        loadingTags: false,
        tagSearch: "",
        filterSearchTags: "",
        showTag: false,
        stopTags: false,
        stopSeller: false,
        sellers: [
          {
            id: 0,
            email: "Todos os vendedores"
          }
        ],
        sellerPage: 1,
        selectedSellers: [
          {
            id: 0,
            email: "Todos os vendedores"
          }
        ],
        loadingForms: false,
        selectedForms: [],
        forms: [],
        generateState: "Clique aqui para gerar seu relatório customizado",
        dateRange: {
          startDate: moment().subtract(7,"days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD")
        },
        generating: false,
        generatedOnce: false,
        graphs: [],
        tagColorClasses: {
          info: "#004392",
          danger: "#d31408",
          warning: "#ff9e44",
          primary: "#16C3B5",
          success: "#4ea934",
          default: "#141414",
        },
        localeDate: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Aplicar",
          cancelLabel: "Cancelar",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          monthNames: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
          firstDay: 0,
        },
        typeGraph: "horizontal-bar",
        management: {}
      }
    },
    watch: {
      tagSearch: "filterSearchTagsUpdate",
      selectedTags: "filterSearchTagsUpdate",
      tags: "filterSearchTagsUpdate"
    },
    computed: {
      dateRanges() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
  
        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
  
        let lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);
        lastWeek.setHours(0, 0, 0, 0);
  
        return {
          Hoje: [today, today],
          // Ontem: [yesterday, yesterday],
          "Última semana": [lastWeek, today],
          "Este mês": [
            new Date(today.getFullYear(), today.getMonth(), 1),
            new Date(today.getFullYear(), today.getMonth(), 30),
          ],
          "Este ano": [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31),
          ],
          "Último mês": [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0),
          ],
          "Todo período": [
            new Date(2016, 0, 1),
            new Date(today.getFullYear(), today.getMonth(), today.getDate()),
          ],
        };
      },
    },
    methods: {
      getTags() {
        let data = {
          id: `list?per_page=1000`,
        };
  
        serviceTag
          .read(data)
          .then((resp) => {
            this.tags = this.tags = resp.data;
          })
          .finally(() => {
            this.loadingTags = false;
          });
      },
      addTag(tag){
        this.selectedTags.push(tag);
        this.alertAlteration();
      },
      removeTag(tagIndex){
        if(this.generating){
          return;
        }
        this.selectedTags.splice(tagIndex, 1);
        this.alertAlteration();
      },
      filterSearchTagsUpdate() {
        this.filterSearchTags = this.tags.filter((item) => {
          const nameMatches = item.name
            ?.toLowerCase()
            .includes(this.tagSearch.toLowerCase());
          const idMatches = this.selectedTags.some((tag) => tag.id === item.id);
          return nameMatches && !idMatches;
        });
      },
      debounceSeller: _.debounce(function (query) {
        if (query === "") {
          this.resetComponentVendedor();
        } else {
          this.searchVendedor = query;
          this.sellerPage = 1;
          // this.sellers = [];
          this.stopSeller = false;
          this.getSellers(this.sellerPage, query);
        }
      }, 500),
      getSellers(page = 1, query = "") {
        if (this.stopSeller) {
          return;
        }
  
        var data = {
          page: `${page}&order_by=email&order=ASC&search=${query}`,
        };
  
        serviceSeller
          .search(data)
          .then((resp) => {
            if (resp.data.length < resp.per_page) {
              this.stopSeller = true;
            }
            this.sellers = this.sellers.concat(resp.data);
            this.sellers = this.sellers.filter(
              (item, index, opt) =>
                index === opt.findIndex((t) => t.id === item.id)
            );
          })
          .catch((err) => {});
      },
      verifySellersSelected(sellers) {
        const allSellers = sellers.filter(seller => seller.id == 0);
        if(allSellers.length){
          this.selectedSellers = allSellers;
          if(sellers.length > 1 && sellers[sellers.length -1].id != 0){
            this.customAlert(`Remova a opção "Todos os vendedores" para selecionar um vendedor`, "danger");
          }
          return;
        }
  
        this.selectedSellers = sellers;
        this.alertAlteration();
      },
      debounceForms: _.debounce(function (query) {
        if (query === "") {
          this.loadingForms = false;
          return;
        }
        this.stopForm = false;
        this.getForms(1, query);
      }, 500),
  
      getForms(page = 1, query = "") {
        if (this.stopForm == true) {
          this.loadingForms = false;
          return;
        }
        var data = {
          id: `?page=${page}&order_by=title&order=ASC&status=active&search=${query}`,
        };
        serviceForm
          .read(data)
          .then((resp) => {
            if (resp.data.length < resp.per_page && query == "") {
              this.stopForm = true;
            }
            this.forms = this.forms.concat(resp.data);
            this.forms = this.forms.filter(
              (item, index, opt) =>
                index === opt.findIndex((t) => t.id === item.id)
            );
          })
          .catch((error) => {});
      },
      customAlert(content, variant = "success"){
        this.$bvToast.toast(content, {
          title: "Relatório de tags",
          variant: variant,
          autoHideDelay: 5000,
          appendToast: true,
        });
      },
      changeState(generating = false){
        if(generating){
          this.generating = true;
          this.$refs.generateGraph.classList.add("shine");
          this.$refs.tags.style.opacity = .5;
          this.generateState= "Gerando relatório...";
          return;
        }
        this.generating = false;
        this.$refs.generateGraph.classList.remove("shine");
        this.$refs.tags.style.opacity = 1;
        this.generateState= "Clique aqui para gerar seu relatório customizado";
        this.generatedOnce = true;
      },
      generateGraph() {
        if (!this.selectedTags.length) {
          this.customAlert("Necessário selecionar ao menos uma tag", 'danger');
          return;
        }
  
        if (this.generating) {
          return;
        }
  
        this.changeState(true);
        this.graphs = [];
  
        if(!this.selectedSellers.length){
          this.selectedSellers = [{
            id: 0,
            email: "Todos os vendedores"
          }];
        }
  
        const notCount = [];
        const selectedTags = this.selectedTags.map((tag) => {
          return {
            name: tag.name, 
            color: tag.color
          }
        });
        const requests = this.selectedSellers.map((seller) => {
          const data = {
            id: "/management",
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
            tags: this.selectedTags.map((tag) => tag.id),
            forms: this.selectedForms ? this.selectedForms.map(form => form.id) : [],
            sellerId: seller.id
          };
  
          return serviceTag.createId(data)
            .then((graph) => {
              let hasGraph = false;
              for (const key in graph.graph) {
                if (graph.graph[key].count) {
                  hasGraph = true;
                }
              }
  
              const graphKeys = new Set(Object.keys(graph.graph));
              selectedTags.forEach(tag => {
                if (!graphKeys.has(tag.name)) {
                  graph.graph[tag.name] = {
                    count: 0,
                    color: tag.color
                  };
                }
              });
  
              if (!hasGraph) {
                notCount.push(seller.first_name  ? (seller.first_name + ' ' + seller.last_name) : this.formatNameFromEmail(seller.email));
              } else {
                this.graphs.push({ ...graph, sellerName: seller.first_name ? (seller.first_name + ' ' + seller.last_name) : this.formatNameFromEmail(seller.email) });
              }
            })
            .catch((e) => {
              this.customAlert("Erro ao gerar relatório", "danger");
            });
        });
  
        Promise.all(requests).then(() => {
          this.changeState(false);
          this.showNotCountMessages(notCount);
        });
      },
      formatNameFromEmail(email) {
        const name = email.split('@')[0];
        
        const splitedName = name.replace(/\./g, ' ');
  
        if(name === "Todos os vendedores"){
          return "Todos os vendedores";
        }
  
        const formatedName = splitedName.split(' ').map(palavra => 
          palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
        ).join(' ');
  
        return formatedName;
      },
      showNotCountMessages(names) {
        if(!names.length){
          return;
        }
        
        const joinedNames = names.join(", ");
        this.customAlert(
          `Não houve ${this.selectedTags.length === 1 ? 'movimentação na tag selecionada' : 'movimentações nas tags selecionadas'} a partir do filtro utilizado ${names.length === 1 ? 'para o vendedor ' + joinedNames : 'para os vendedores ' + joinedNames}`,
          "danger"
        );
      },
      returnPizzaOptions(graph) {
        const labels = [];
        const colors = [];
        for (const key in graph) {
          const element = graph[key];
          const tagName = key && key.length > 20 ? key.substr(0, 20) + "..." : key;
          labels.unshift(tagName);
          colors.unshift(this.tagColorClasses[element.color]);
        };
          
        return {
          labels: labels,
          colors: colors,
          chart: {
            height: 350,
            type: "pie",
            toolbar: {
              show: true,
            },
          },
          legend: {
            position: 'bottom'
          }
        };
      },
      returnBarOptions(graph) {
        const labels = [];

        for (const key in graph) {
          labels.unshift(key);
        }

        return {
          chart: {
            height: 350,
            type: 'bar',
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '25%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: labels,
          },
          yaxis: {
            title: {
              text: '',
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        };
      },
      returnPizzaValues(graph) {
        const values = [];
        for (const key in graph) {
          const element = graph[key];
          values.unshift(element.count);
        };
        return values;
      },
      returnBarValues(graph) {
        const values = [];    
        for (const key in graph) {
          const element = graph[key];
          values.unshift({
            x: key,
            y: element.count,
            fillColor: this.tagColorClasses[element.color]
          });
        };
  
        return [{
          name: 'Quantidade',
          data: values
        }];
      },
      alertAlteration() {
        if(!this.graphs.length){
          return;
        }
        this.$refs.reload.classList.add('alteration');
        setTimeout(() => {
          this.$refs.reload.classList.remove('alteration');
        }, 600);
      },
      saveManagement() {
        if(!this.selectedTags.length){
          this.customAlert("Necessário selecionar ao menos uma tag", 'danger');
          return;
        }
        this.$bvModal.show("management-modal");
      },
      setManagement(managementId) {
        this.generatedOnce = true;

        serviceTag
          .read({id: `/management/${managementId}`})
          .then((response) => {
              this.management = response;
              //Forms
              const forms = response.forms
              .map(formId => response.formsData.find(form => form.id === formId))
              .filter(form => form !== undefined);
              this.selectedForms = forms;

              //Sellers
              const sellers = response.sellers
              .map(sellerId => response.sellersData.find(seller => seller.id === sellerId))
              .filter(seller => seller !== undefined);
              this.selectedSellers = sellers;

              //Tags
              const tags = response.tags
              .map(tagId => response.tagsData.find(tag => tag.id === tagId))
              .filter(tag => tag !== undefined);
              this.selectedTags = tags;
              this.typeGraph = response.type_graph;

              this.dateRange.startDate = moment().subtract(response.period, "days").format("YYYY-MM-DD"),
              this.dateRange.endDate = moment().format("YYYY-MM-DD"),

              this.generateGraph();
          }).catch(() => {
            this.customAlert("Erro ao buscar relatório", "danger");
          })
      },
      returnHorizontalBarOptions(graph) {
        const labels = [];
        
        for (const key in graph) {
          const tagName = key && key.length > 20 ? key.substr(0, 20) + "..." : key;
          labels.unshift(tagName);
        }

        return {
          chart: {
            height: 350,
            type: 'bar',
            toolbar: {
              show: true,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: true,
              barHeight: '50%',
              isFunnel: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: labels,
          },
          yaxis: {
            title: {
              text: '',
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        };
      },
      returnHorizontalBarsValues(graph) {
        const values = [];
        for (const key in graph) {
          const element = graph[key];
          values.unshift({
            x: key,
            y: element.count,
            fillColor: this.tagColorClasses[element.color]
          });
        };

        return [{
          name: 'Quantidade',
          data: values
        }];
      },
      canUseTagManagement() {
            serviceTag.read({id: `/management/access`}).then((r) => {
            }).catch(err => {
                this.$router.push("/dynamicRoute/AccessDenied");
            });
        },
      },
    created() {
      if (this.$store.getters.recursos.nstrategic < 1) {
        this.$router.push("/dynamicRoute/AccessDenied");
      }
      this.canUseTagManagement();
    },  
    mounted() {
      this.getTags();
      const managementId = this.$route.params.id;
      if(managementId){
        this.setManagement(managementId);
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .tag-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .grid-filters{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 0 20px;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }
  
  .box {
    padding: 20px;
    border: 1px solid var(--white-medium);
    border-radius: 10px;
    position: relative;
  }
  
  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    .graph-icon {
      width: 30px;
    }
  }
  
  .add-button {
    background: var(--greenn2);
    color: var(--greenn);
  }
  
  .select-tag{
    position: relative;
  
    #tags-all {
      max-height: 250px;
      overflow-y: auto;
      top: 65px;
      left: -122px;
  
      input {
        height: 25px !important;
        padding-left: 32px !important;
      }
  
      .searchIcon {
        position: absolute;
        height: 15px;
        top: 24px;
        left: 24px;
      }
  
      .container-tag:hover {
        filter: brightness(0.8);
      }
  
      .container-tag {
        cursor: pointer;
  
        * {
          cursor: pointer;
        }
      }
  
    }
    
    .lead-tags {
      .lead-tag-name {
        margin: 0px;
        padding: 0px;
        max-width: 220px;
        overflow: hidden;
        height: 15px;
        text-overflow: ellipsis;
        border-radius: 0;
      }
      div {
        width: 100%;
      }
  
      span {
        font-size: 12px;
        color: white;
        text-transform: uppercase;
        padding: 0px 8px;
        margin: 0 4px 2px 0;
        border-radius: 5px;
        width: fit-content;
        display: inline-block;
        cursor: default;
        transition: all 0.3s;
  
        .delete-tag {
          padding: 0 0 0 6px;
          cursor: pointer;
          transition: all 0.3s;
  
          &:hover {
            filter: drop-shadow(0.5px 0.5px 0px white) drop-shadow(-0.5px 0.5px 0px white);
          }
        }
      }
  
      .add-tag {
        position: relative;
        color: var(--greenn);
        border: 1px solid var(--greenn) !important;
        transition: all 0.3s;
        cursor: pointer;
  
        &:hover {
          background-color: var(--greenn-transparent);
        }
      }
    }
    .drop-bottom {
      width: 285px;
      position: absolute;
      box-shadow: 2px 2px 10px 1px var(--white-medium);
      background-color: var(--white);
      z-index: 2;
      left: 0px;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(232, 232, 232);
      border-image: initial;
      border-radius: 10px;
      padding: 15px;
      margin: 0px;
    }
  }
  .tag-body {
    padding: 5px 18px 5px 8px;
    border-radius: 10px;
    display: block;
    width: 100px !important;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    color: white;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    position: relative;
    &::after {
      content: 'x';
      position: absolute;
      color: white;
      font-weight: 600;
      top: 5px;
      right: 5px;
      font-size: 11px;
      text-transform: uppercase;
      display: inline-block;
    }
  }
  
  .info_color {
    background: var(--bluee);
  }
  
  .danger_color {
    background: var(--red);
  }
  
  .warning_color {
    background: var(--orange);
  }
  
  .primary_color {
    background: var(--greenn-light);
  }
  
  .success_color {
    background: var(--greenn);
  }
  
  .default_color {
    background: var(--gray01);
  }
  
  p {
    font-size: 16px;
    color: #81858e !important;
    font-weight: 500;
    margin: 0;
  }
  
  .graphs-container {
    position: relative;
    max-height: 600px;
    height: 100vh;
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow: hidden;
    .seller-name {
      position: absolute;
      font-size: 18px;
      top: 25px;
      left: 25px;
    }
  }
  
  .reload {
    width: 28px;
  }
  
  .label-header{
    display: flex;
    margin-bottom: 3px;
    margin-left: 2px;
    img {
      height: fit-content;
      margin: 0px 5px 0 0;
    }
    label{
      margin: 0 !important;
    }
  }
  
  .input-box{
    display: grid;
    gap: 2px;
  }
  
  .add-graph {
    height: 100%;
    width: 100%;
    display: grid;
    place-content: center;
    justify-items: center;
    gap: 10px;
    background-color: var(--white-medium);
    border-radius: 10px;
    cursor: pointer;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
  
  .grid-graph {
    display: grid;
    width: 100%;
    place-content: center;
    transform: scale(1.4);
  }
  
  .flex-graph {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    max-width: calc(3 * 250px + 2 * 10px);
    margin: 0 auto;
    gap: 20px;
    overflow-y: auto;
    place-content: center;
  }
  
  .alteration {
    animation: expand ease-in-out .5s alternate;
  }
  
  .bar-chart {
    width: 580px;
  }

  .rotate {
    transform: rotate(90deg);
  }
  
  @media (max-width: 1060px) {
    .bar-chart {
      width: 380px;
    }
  }
  
  @keyframes expand {
    to {
      transform: scale(1.12);
    }
  }
  </style>
  